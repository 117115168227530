import { useMutation, useQuery } from "@tanstack/react-query";
import { getLive, updateLive } from "../utils/api";
import { Table, Image, Typography, Switch, message } from "antd";
import type { ColumnsType } from "antd/es/table";

import { Link } from "react-router-dom";
import { useAuth } from "../utils/store";

const Live = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAuth((state) => state.user);
  const {
    data,
    isPending: isLoading,
    refetch,
  } = useQuery({
    queryKey: ["live-streams"],
    queryFn: () => (user?.viewGifts ? getLive() : []),
    refetchInterval: 30000,
  });

  const columns: ColumnsType<any> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            <Link to={`/live/${record.id}`} target="_blank">
              Join
            </Link>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      render(value, record) {
        return <div>{value.name}</div>;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render(value, record) {
        return <div>{value.name}</div>;
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render(value, record) {
        return <div>{value.name}</div>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render(value, record, index) {
        return (
          <UpdateActive id={record._id} active={value} refetch={refetch} />
        );
      },
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Live
        </Typography>
        {/* <AddData refech={refetch} /> */}
      </div>
      <Table
        footer={
          user?.viewAdmin
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default Live;

const UpdateActive = ({ id, active, refetch }: any) => {
  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) => await updateLive(id, values),
    onSuccess: () => {
      message.success(`Updated`);
      refetch();
    },
  });
  return (
    <Switch
      loading={isLoading2}
      checked={active}
      onChange={(value) => {
        mutate({ active: value });
      }}
    />
  );
};
