import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  UserNews,
  addUser,
  completeSalary,
  getStates,
  getUsers,
  getwallet,
  updateAdd,
  updateUser,
  updateWallet,
  userNotifcation,
  userSerach,
} from "../utils/api";

import { UploadOutlined } from "@ant-design/icons";

import {
  Table,
  Button,
  Modal,
  Image,
  Typography,
  Form,
  Input,
  message,
  Upload,
  DatePicker,
  Select,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import useSearch from "../components/Search2";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/store";
import SelectStateCity from "../components/SelectStateCity";

interface User {
  id: number;
  uniqueCode: string;
  name: string;
  email: string;
  phoneNumber: string;
  dob: string;
  newsTypeUser: string;
  view: string;
  _id: string;
  isSalaryPending: boolean;
  tangentNewsSalary: number;
}

const Users = () => {
  const user = useAuth((state) => state.user);
  const [page, setPage] = useState(1);

  const [searchData, setSearchData] = useState<any>({});
  const search = useSearch(
    () => {
      setPage(1);
      setSearchData(null);
    },
    (data: any) => {
      setSearchData(data);
    }
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users", page, searchData],
    queryFn: async () =>
      user?.viewUser
        ? searchData?.searchQuery
          ? await userSerach(searchData)
          : await getUsers(page)
        : [],
  });
  const columns: ColumnsType<User> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",

              flexDirection: "column",
              gap: 10,
            }}
          >
            {" "}
            {user?.updateUser && (
              <>
                <AddData refech={refetch} update={record} />
                <UserWallet row={record} />
                <View row={record} />
                <View2 row={record} />
                {record?.isSalaryPending && <CompleteSalary row={record} />}
                <UpdateUserTypeNews row={record} refetch={refetch} />
              </>
            )}
          </div>
        );
      },
    },

    {
      title: "Image",
      dataIndex: "image",
      render(value) {
        return <Image src={value} width={100} height={100} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      ...search("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...search("email"),
    },
    {
      title: "State",
      dataIndex: ["state", "name"],
    },
    {
      title: "City",
      dataIndex: ["city", "name"],
    },
    {
      title: "Area",
      dataIndex: ["area", "name"],
    },
    {
      title: "Preferred Location",
      dataIndex: "preferredLocation",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
    },
    {
      title: "User Type",
      dataIndex: "newsTypeUser",
    },
    {
      title: " Salary Pending",
      dataIndex: "isSalaryPending ",
      render(value) {
        return <span>{value ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Salary",
      dataIndex: ["tangentNewsSalary"],
    },
  ];

  return (
    <div
      style={{
        padding: 24,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Users
        </Typography>
        {user?.addUser && <AddData refech={refetch} />}
      </div>
      <Table
        footer={
          user?.viewUser
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data?.users}
        loading={isLoading}
        scroll={{ x: 1500 }}
        pagination={{
          total: data?.totalData,
          pageSize: 20,
          onChange: (e) => {
            setPage(e);
          },
          pageSizeOptions: ["20"],
        }}
      />
    </div>
  );
};

export default Users;

const UpdateUserTypeNews = ({ row, refetch }: { row: User; refetch: any }) => {
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async () => {
      return await updateUser(row?._id, {
        newsTypeUser:
          row?.newsTypeUser === "Normal" ||
          row?.newsTypeUser === null ||
          row?.newsTypeUser === undefined
            ? "Reporter"
            : "Normal",
      });
    },
    onSuccess() {
      message.success("User Type Updated Successfully");
      refetch();
    },
  });

  return (
    <Button
      type="primary"
      danger
      onClick={() => {
        mutate();
      }}
      loading={isLoading}
    >
      {row?.newsTypeUser ?? "Normal"}
    </Button>
  );
};

const CompleteSalary = ({ row }: { row: User }) => {
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async () => {
      return await completeSalary(row?._id, {
        salary: row?.tangentNewsSalary,
      });
    },
    onSuccess() {
      message.success("Wallet Updated Successfully");
    },
  });

  return (
    <Button
      type="primary"
      onClick={() => {
        mutate();
      }}
      loading={isLoading}
    >
      Complete Salary
    </Button>
  );
};

const AddData = ({
  refech,

  update,
}: {
  refech: any;

  update?: any;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("rewardType", form);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values: any) =>
      update ? await updateUser(update._id, values) : await addUser(values),
    onSuccess: () => {
      messageApi.success(`Added`);
      //reset form
      !update && form.resetFields();
      refech();
      setVisible(false);
    },
    onError: (err: any) => {
      messageApi.error(err?.response?.data?.message);
    },
  });

  const { data: states, isLoading: isLoading3 } = useQuery({
    queryKey: ["states"],
    queryFn: () => getStates(),
  });

  return (
    <div>
      {contextHolder}
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          width: "100%",
        }}
      >
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Edit User" : "Add User"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              email: update?.email,
              name: update?.name,
              phoneNumber: update?.phoneNumber,
              state: update?.state?._id,
              city: update?.city?._id,
              area: update?.area?._id,
              preferredLocation: update?.preferredLocation,
            }
          }
          onFinish={(values) => {
            const data = new FormData();
            if (update) {
              mutate(values);
              return;
            }
            data.append("name", values.name);
            data.append("email", values.email);
            data.append("phoneNumber", values.phoneNumber);
            data.append("image", values.image.file.originFileObj);
            data.append(
              "dob",
              `${values.dob.$D}/${values.dob.$M + 1}/${values.dob.$y}`
            );
            data.append("state", values.state);
            data.append("city", values.city);
            data.append("area", values.area);
            data.append("preferredLocation", values.preferredLocation);

            mutate(data);
          }}
        >
          {" "}
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[
              {
                required: true,
                message: "Please select name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please select email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            required
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Preferred Location"
            name="preferredLocation"
            required
            rules={[
              {
                required: true,
                message: "Please input your preferredLocation!",
              },
            ]}
          >
            <Select
              // preferredLocation -> State , Area , City , Global
              options={[
                {
                  label: "State",
                  value: "State",
                },
                {
                  label: "Area",
                  value: "Area",
                },
                {
                  label: "City",
                  value: "City",
                },
                {
                  label: "Global",
                  value: "Global",
                },
              ]}
            />
          </Form.Item>
          {!update && (
            <>
              {" "}
              <Form.Item
                label="Dob"
                name="dob"
                required
                rules={[
                  {
                    required: true,
                    message: "Please select dob!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Image"
                name="image"
                required
                rules={
                  !update
                    ? [
                        {
                          required: true,
                          message: "Please input your image!",
                        },
                      ]
                    : []
                }
              >
                <Upload name="image">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </>
          )}
          <SelectStateCity form={form} states={states} />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

const View = ({ row }: { row: User }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["userNews", row?._id, isModalOpen],
    queryFn: () => isModalOpen && UserNews(row?._id),
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        News
      </Button>
      <Modal
        title="User News"
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <Table
          columns={[
            {
              title: "Banner",
              dataIndex: "banner",
              render(value) {
                return <Image src={value} />;
              },
            },
            {
              title: "Title",
              dataIndex: "title",
            },
            {
              title: "Description",
              dataIndex: "description",
            },
            {
              title: "URl",
              dataIndex: "url",

              render(value) {
                return (
                  <Link to={value} target="_blank">
                    Link
                  </Link>
                );
              },
            },
            {
              title: "IsVerified",
              dataIndex: "isVerified",
              render(value) {
                return <span>{value ? "Yes" : "No"}</span>;
              },
            },
            {
              title: "News Type",
              dataIndex: "newsType",
            },
          ]}
          dataSource={data}
          loading={isLoading}
          scroll={{ x: 600 }}
        />
      </Modal>
    </>
  );
};
const View2 = ({ row }: { row: User }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["notification", row?._id, isModalOpen],
    queryFn: () => isModalOpen && userNotifcation(row?._id),
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Notifications
      </Button>
      <Modal
        width={800}
        title="Notification"
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <Table
          columns={[
            {
              title: "Image",
              dataIndex: "image",

              render(value) {
                return <Image src={value} width={100} height={100} />;
              },
            },
            {
              title: "Title",
              dataIndex: "title",
            },
            {
              title: "Description",
              dataIndex: "description",
            },

            {
              title: "Read",
              dataIndex: "read",
              render(value) {
                return <span>{value ? "Yes" : "No"}</span>;
              },
            },
            {
              title: "Date",
              dataIndex: "date",
            },
            {
              title: "App",
              dataIndex: "app",
            },
          ]}
          dataSource={data}
          loading={isLoading}
          scroll={{ x: 600 }}
        />
      </Modal>
    </>
  );
};

const UserWallet = ({ row }: { row: User }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { data, isLoading } = useQuery({
    queryKey: ["wallet", row?._id, isModalOpen],
    queryFn: () => isModalOpen && getwallet(row?._id),
  });

  const { mutate } = useMutation({
    mutationFn: async (values: any) => {
      return await updateWallet(values);
    },
    onSuccess() {
      messageApi.success("Wallet Updated Successfully");
    },
  });

  return (
    <>
      {contextHolder}
      <Button
        type="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Wallet
      </Button>
      <Modal
        title="Wallet"
        width={1000}
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        {data?.wallet && (
          <Form
            labelCol={{ span: 6 }}
            layout="horizontal"
            disabled={isLoading}
            style={{
              maxWidth: 600,
              padding: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            initialValues={data?.wallet}
            onFinish={(values) =>
              mutate({
                ...values,
                userId: row?._id,
              })
            }
          >
            {Object?.keys(data?.wallet).map((key) =>
              key === "_id" || key === "user" || key === "__v" ? null : (
                <Form.Item
                  key={key}
                  label={key?.toUpperCase()}
                  name={key}
                  rules={[
                    {
                      required: true,
                      message: "Please input ",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )
            )}

            <Form.Item label="Message" name="message">
              <Input.TextArea />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form>
        )}

        <Table
          columns={[
            {
              title: "title",
              dataIndex: "title",
            },
            {
              title: "Balance",
              dataIndex: "balance",
            },
            {
              title: "Date",
              dataIndex: "date",
            },
            {
              title: "Type",
              dataIndex: "type",
            },
            {
              title: "Transaction From",
              dataIndex: "transactionFrom",
            },
          ]}
          dataSource={data?.history}
          loading={isLoading}
          scroll={{ x: 600 }}
        />
      </Modal>
    </>
  );
};
