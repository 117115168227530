import { useQuery } from "@tanstack/react-query";
import { Form, Select } from "antd";
import React from "react";
import { getAreabyid, getCitybyid } from "../utils/api";

const SelectStateCity = ({
  states,
  form,
}: {
  states: {
    _id: string;
    name: string;
    __v: number;
  }[];
  form: any;
}) => {
  console.log(states);

  const currentState = Form.useWatch("state", form);
  console.log(currentState);
  const currentCity = Form.useWatch("city", form);
  const { data: cities, isLoading } = useQuery({
    queryKey: ["cities", currentState],
    queryFn: async () => currentState && (await getCitybyid(currentState)),
  });

  const { data: areas, isLoading: isLoadingAreas } = useQuery({
    queryKey: ["areas", currentCity],
    queryFn: async () => currentCity && (await getAreabyid(currentCity)),
  });

  console.log(cities);

  return (
    <>
      <Form.Item label="State" name="state" rules={[{ required: true }]}>
        <Select
          options={states.map((state) => ({
            label: state.name,
            value: state._id,
          }))}
          showSearch
          placeholder="Select a state"
          filterOption={(input: string, option: any) => {
            return (
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        />
      </Form.Item>
      {currentState && (
        <Form.Item label="City" name="city" rules={[{ required: true }]}>
          <Select
            loading={isLoading}
            options={cities?.map((city: any) => ({
              label: city.name,
              value: city._id,
            }))}
            showSearch
            placeholder="Select a city"
            filterOption={(input: string, option: any) => {
              return (
                option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          />
        </Form.Item>
      )}

      {currentState && currentCity && (
        <Form.Item label="Area" name="area" rules={[{ required: true }]}>
          <Select
            loading={isLoadingAreas}
            options={areas?.map((area: any) => ({
              label: area.name,
              value: area._id,
            }))}
            showSearch
            placeholder="Select a area"
            filterOption={(input: string, option: any) => {
              return (
                option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          />
        </Form.Item>
      )}
    </>
  );
};

export default SelectStateCity;
