import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteUserNews, getUpgradeRequest, updateRequest } from "../utils/api";
import { Table, Typography, Button, Modal, Form, message, Select } from "antd";
import type { ColumnsType } from "antd/es/table";

import { useState } from "react";
import { useAuth } from "../utils/store";

interface Data {
  _id: string;
  status: string;
  user: {
    name: string;
    email: string;
    tangentNewsSalary: number;
    isSalaryPending: number;
  };
}

const Request = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["request"],
    queryFn: () => user?.viewUpgradeRequest && getUpgradeRequest(),
  });
  const user = useAuth((state) => state.user);
  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) => await deleteUserNews(values),
    onSuccess: () => {
      messageApi.success(`Deleted News`);
      refetch();
    },
  });

  const columns: ColumnsType<Data> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            <Update data={record} refech={refetch} />{" "}
            {/* <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => mutate(record._id)}
            >
              <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                Delete
              </Button>
            </Popconfirm> */}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Salary",
      dataIndex: ["user", "tangentNewsSalary"],
    },
    {
      title: "User Name",
      dataIndex: ["user", "name"],
    },
    {
      title: "User Email",
      dataIndex: ["user", "email"],
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
        Reporter Verification
      </Typography>
      <Table
        footer={
          user?.viewUpgradeRequest
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 700 }}
      />
    </div>
  );
};

export default Request;

const Update = ({ data, refech }: { data: Data; refech: any }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data2: any) => updateRequest(data?._id, data2),
    onSuccess: () => {
      messageApi.success(`Updated `);
      refech();
      setVisible(false);
    },
  });

  const [form] = Form.useForm();

  const status = Form.useWatch("status", form);
  return (
    <div>
      {contextHolder}
      <Button type="primary" onClick={() => setVisible(true)}>
        Update
      </Button>
      <Modal
        title="Update Reporter Verification"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          layout="horizontal"
          disabled={isLoading}
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={{
            status: data?.status,
          }}
          onFinish={(values) => {
            let data2 = values;
            if (values?.status === "Accepted") {
              data2 = { ...values, salary: data?.user?.isSalaryPending };
            }
            mutate(data2);
          }}
        >
          <Form.Item label="Status" name="status" required>
            <Select
              options={[
                { label: "Requested", value: "Requested" },
                { label: "Accepted", value: "Accepted" },
                { label: "Rejected", value: "Rejected" },
              ]}
            />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
