import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useParams } from "react-router-dom";
import { useAuth } from "../utils/store";

function randomID(len: any) {
  let result = "";
  if (result) return result;
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(url = window.location.href) {
  let urlStr = url.split("?")[1];
  return new URLSearchParams(urlStr);
}

export default function LiveVideo() {
  const params = useParams();
  const user = useAuth((state) => state.user);

  const roomID = params.id || randomID(5);
  let role_str = getUrlParams(window.location.href).get("role") || "Host";
  const role = ZegoUIKitPrebuilt.Audience;

  let sharedLinks: any = [];
  sharedLinks.push({
    name: "Join as audience",
    url:
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?roomID=" +
      roomID +
      "&role=Audience",
  });
  // generate Kit Token
  const appID = 582656473;
  const serverSecret = "ac82c5f9cbb97b20b56ea9f5a88c8bb3";
  // start the call
  //   const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
  //     appID,
  //     "04AAAAAGVm89YAEG0ycTU0MWplcXR2dWkybGEAsIFpii1wOWYYQnxzQFH7aSk1/9gZmMMDud6IgbJsHSDIV+KWx34Mveylj2ooM2XoNcPd4qCwwYKiYrzHWzv5vIMVHtbbwWT22F8T77ufhMARaQOUgYpcfIn49jrbJLPK7WTRbEzGnMI1bx9Rc1mzNg5hRWzkXR0u6w0kkuHGe0Mh9sq9o0PQHsoReCfLsBMQdxWfm42nyPTkb4kyUhlIDrboFAui/4f6cLQeNly4viq8",
  //     roomID,
  //     "1234567890" || randomID(10),
  //     "audience"
  //   );

  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
    appID,
    serverSecret,
    roomID,
    randomID(5),
    randomID(5)
  );

  let myMeeting = async (element: any) => {
    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
          role,
        },
      },
      sharedLinks,
    });
  };

  return (
    <div
      className="myCallContainer"
      ref={myMeeting}
      style={{
        width: "80dvw",
        height: "100dvh",
      }}
    ></div>
  );
}
