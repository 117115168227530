import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addSpinner,
  deleteSpinner,
  getSpinner,
  updateAdd,
  updateSpinner,
} from "../utils/api";
import {
  Table,
  Image,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";

import { useState } from "react";
import { useAuth } from "../utils/store";

interface Data {
  _id: string;
  rewardType: string;
  gift: string;
  coin: number;
  probability: number;
}

const Spinner = () => {
  const user = useAuth((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["spinner"],
    queryFn: () => (user?.viewSpinner ? getSpinner() : []),
  });

  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) => await deleteSpinner(values),
    onSuccess: () => {
      messageApi.success(`Deleted`);
      refetch();
    },
  });

  const columns: ColumnsType<Data> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            {user?.updateSpinner && (
              <AddData update={record} refech={refetch} />
            )}

            {user?.deleteSpinner && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Gift",
      dataIndex: "gift",

      render(value, record) {
        return (
          <Image
            src={
              value ??
              "https://img.freepik.com/free-vector/digital-currency-indian-rupee-symbol-golden-coin_1017-42459.jpg?w=826&t=st=1706010568~exp=1706011168~hmac=57965b7adbce3ca5e87b7d95ec9cd7d297ec02e15e307f874fa7ffea3e399d53"
            }
            height={100}
            width={100}
          />
        );
      },
    },
    {
      title: "Reward Type",
      dataIndex: "rewardType",
    },
    {
      title: "Coin",
      dataIndex: "coin",
    },
    {
      title: "Probability",
      dataIndex: "probability",
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Spinner
        </Typography>
        {user?.addSpinner && <AddData refech={refetch} />}
      </div>
      <Table
        footer={
          user?.viewSpinner
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default Spinner;

const AddData = ({
  refech,

  update,
}: {
  refech: any;

  update?: any;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("rewardType", form);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values: any) =>
      update
        ? await updateSpinner(update._id, values)
        : await addSpinner(values),
    onSuccess: () => {
      messageApi.success(`Added`);
      //reset form
      !update && form.resetFields();
      refech();
      setVisible(false);
    },
    onError: (err: any) => {
      messageApi.error(err?.response?.data?.message);
    },
  });

  return (
    <div>
      {contextHolder}
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update Spinner" : "Add Spinner"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              rewardType: update.rewardType,
              coin: update.coin,
              probability: update.probability,
            }
          }
          onFinish={(values) => {
            const data = new FormData();
            data.append("rewardType", values.rewardType);
            if (values.rewardType === "Coin") data.append("coin", values.coin);
            else {
              if (values.image) {
                data.append("image", values.image.file.originFileObj);
              }
            }
            data.append("probability", values.probability);

            mutate(data);
          }}
        >
          {" "}
          <Form.Item
            label="Type"
            name="rewardType"
            required
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select
              options={[
                { label: "Coin", value: "Coin" },
                { label: "Gift", value: "Gift" },
              ]}
            />
          </Form.Item>
          {type === "Coin" ? (
            <>
              <Form.Item
                label="Coin"
                name="coin"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your coin",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="Image"
                name="image"
                required
                rules={
                  !update
                    ? [
                        {
                          required: true,
                          message: "Please input your image!",
                        },
                      ]
                    : []
                }
              >
                <Upload name="image">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Probability"
            name="probability"
            required
            rules={[
              {
                required: true,
                message: "Please input your probability",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
