import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Statistic,
  Typography,
  message,
} from "antd";
import React from "react";
import { getDash, getSystemValues, putsystemval } from "../utils/api";
import { useQuery, useMutation } from "@tanstack/react-query";
import { StarOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/store";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  const user = useAuth((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["systemValues"],
    queryFn: () => user?.adminValues && getSystemValues(),
  });
  const { data: dash, isLoading: isLoading3 } = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => user?.dashboard && getDash(),
  });

  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) =>
      user?.adminValues && (await putsystemval(values)),

    onSuccess: () => {
      message.success(`Updated`);
    },
  });

  const colors = [
    "#C70039",
    "#FF5733",
    "#FFC300",
    "#219C90",
    "#750E21",
    "#161A30",
  ];

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%", padding: 20 }}
      >
        <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
          Dashboard
        </Typography>
        {!user?.dashboard && (
          <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
            You are not authorized to view this page
          </Typography>
        )}
        <Row
          //mobile one only dekstop 3

          gutter={[16, 16]}
        >
          {dash && (
            <>
              <Col span={8} xs={{ span: 24 }} md={{ span: 8 }}>
                <Link to="/news">
                  <Card
                    title={"News"}
                    bordered={false}
                    headStyle={{
                      backgroundColor: colors[0],
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <Statistic
                      // title={key?.toUpperCase()}
                      value={dash?.news}
                      valueStyle={{ color: "#3f8600" }}
                      // prefix={<ArrowUpOutlined />}
                      // suffix="%"
                    />
                  </Card>
                </Link>
              </Col>
              <Col span={8} xs={{ span: 24 }} md={{ span: 8 }}>
                <Link to="/users">
                  <Card
                    title={"Users"}
                    bordered={false}
                    headStyle={{
                      backgroundColor: colors[1],
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <Statistic
                      // title={key?.toUpperCase()}
                      value={dash?.users}
                      valueStyle={{ color: "#3f8600" }}
                      // prefix={<ArrowUpOutlined />}
                      // suffix="%"
                    />
                  </Card>
                </Link>
              </Col>
              <Col span={8} xs={{ span: 24 }} md={{ span: 8 }}>
                <Link to="/ads">
                  <Card
                    title={"Ads"}
                    bordered={false}
                    headStyle={{
                      backgroundColor: colors[2],
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <Statistic
                      // title={key?.toUpperCase()}
                      value={dash?.ads}
                      valueStyle={{ color: "#3f8600" }}
                      // prefix={<ArrowUpOutlined />}
                      // suffix="%"
                    />
                  </Card>
                </Link>
              </Col>
              <Col span={8} xs={{ span: 24 }} md={{ span: 8 }}>
                <Card
                  title={"Comments"}
                  bordered={false}
                  headStyle={{
                    backgroundColor: colors[3],
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <Statistic
                    // title={key?.toUpperCase()}
                    value={dash?.comments}
                    valueStyle={{ color: "#3f8600" }}
                    // prefix={<ArrowUpOutlined />}
                    // suffix="%"
                  />
                </Card>
              </Col>
              <Col span={8} xs={{ span: 24 }} md={{ span: 8 }}>
                <Link to="/banner">
                  <Card
                    title={"Banners"}
                    bordered={false}
                    headStyle={{
                      backgroundColor: colors[4],
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <Statistic
                      // title={key?.toUpperCase()}
                      value={dash?.banners}
                      valueStyle={{ color: "#3f8600" }}
                      // prefix={<ArrowUpOutlined />}
                      // suffix="%"
                    />
                  </Card>
                </Link>
              </Col>
            </>
          )}
        </Row>
      </Space>
      {data && (
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          disabled={isLoading}
          style={{
            padding: 20,
            width: "100%",
          }}
          initialValues={data}
          onFinish={(values) => mutate(values)}
        >
          <div className="grid-2">
            <Form.Item
              label={"App Sharing Coin"}
              name={"appSharingCoin"}
              labelCol={{
                span: 8,
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Wallet Admin Charges"}
              name={"walletAdminCharges"}
              labelCol={{
                span: 12,
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Content Sharing Coin"}
              name={"contentSharingCoin"}
              labelCol={{
                span: 8,
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={"Minimum Withdrawal Amount"}
              name={"minimumWithdrawalAmount"}
              labelCol={{
                span: 12,
              }}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label={"Rating Coin"}
              name={"ratingCoin"}
              labelCol={{
                span: 8,
              }}
            >
              <Input />
            </Form.Item> */}
          </div>

          <Space
            direction="vertical"
            size="large"
            style={{
              width: "100%",
            }}
          >
            <Typography
              style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}
            >
              Comment
            </Typography>
            <div className="grid-2">
              {Object?.keys(data)?.map((key) => {
                return key === "_id" || key === "__v" ? null : key.includes(
                    "comment"
                  ) ? (
                  <Form.Item
                    key={key}
                    label={
                      <p>
                        {" "}
                        {"Comment " + key.split("comment")[1].split("")[0]}{" "}
                      </p>
                    }
                    name={key}
                    labelCol={{
                      span: 8,
                    }}
                  >
                    <Input />
                  </Form.Item>
                ) : null;
              })}
            </div>
          </Space>
          <Space
            direction="vertical"
            size="large"
            style={{
              width: "100%",
            }}
          >
            <Typography
              style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}
            >
              Rating
            </Typography>
            <div className="grid-2">
              {Object?.keys(data)?.map((key) => {
                return key === "_id" || key === "__v" ? null : key.includes(
                    "rating"
                  ) && !key?.includes("Coin") ? (
                  <Form.Item
                    key={key}
                    label={
                      <p>
                        {" "}
                        {"Rating " + key.split("rating")[1].split("")[0]}{" "}
                        <StarOutlined
                          style={{
                            color: "#c4950d",
                          }}
                        />
                      </p>
                    }
                    name={key}
                    labelCol={{
                      span: 8,
                    }}
                  >
                    <Input />
                  </Form.Item>
                ) : null;
              })}
            </div>
          </Space>
          <Button type="primary" htmlType="submit" loading={isLoading2}>
            Submit
          </Button>
        </Form>
      )}
    </>
  );
};

export default () => <Home />;
