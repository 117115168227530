import { useMutation, useQuery } from "@tanstack/react-query";
import {
  UpdateUserNews,
  addComment,
  addNews,
  deleteComment,
  deleteUserNews,
  getAllComments,
  getNews,
  getStates,
} from "../utils/api";
import {
  Table,
  Image,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  message,
  Popconfirm,
  InputNumber,
  Upload,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { UploadOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useAuth } from "../utils/store";
import useSearch from "../components/Search";
import SelectStateCity from "../components/SelectStateCity";

interface News {
  id: number;
  banner: string;
  title: string;
  description: string;
  url: string;
  isVerified: boolean;
  newsType: string;
  _id: string;
  rating: number;
  ratingCount: number;
  state: {
    _id: string;
    name: string;
    __v: number;
  };
  city: {
    _id: string;
    name: string;
    __v: number;
  };
  area: {
    _id: string;
    name: string;
    __v: number;
  };
  view: number;
}

const News = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["news"],
    queryFn: () => (user?.viewNews ? getNews() : []),
  });

  const search = useSearch();
  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) => await deleteUserNews(values),
    onSuccess: () => {
      messageApi.success(`Deleted News`);
      refetch();
    },
  });
  const { data: states, isLoading: isLoading3 } = useQuery({
    queryKey: ["states"],
    queryFn: () => getStates(),
  });

  const columns: ColumnsType<News> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {user?.updateNews && (
              <AddData update={record} refech={refetch} states={states} />
            )}
            {user?.updateNews && <AddComment row={record} refetch={refetch} />}
            <View row={record} />
            {user?.deleteNews && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Banner",
      dataIndex: "banner",
      render(value) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      ...search("title"),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "URl",
      dataIndex: "url",

      render(value) {
        return (
          <Link to={value} target="_blank">
            Link
          </Link>
        );
      },
    },
    {
      title: "Verified",
      dataIndex: "Verified",
      render(value, record) {
        return <UpdateVerified row={record} refetch={refetch} />;
      },
    },
    {
      title: "News Type",
      dataIndex: "newsType",
    },
    {
      title: "State",
      dataIndex: ["state", "name"],
    },
    {
      title: "City",
      dataIndex: ["city", "name"],
    },
    {
      title: "Area",
      dataIndex: ["area", "name"],
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          News
        </Typography>
        {user?.addNews && <AddData refech={refetch} states={states} />}
      </div>
      <Table
        footer={
          user?.viewNews
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};

export default News;

const UpdateVerified = ({ row, refetch }: { row: News; refetch: any }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values: any) => await UpdateUserNews(row._id, values),
    onSuccess: () => {
      message.success(`Updated News`);
      refetch();
    },
  });
  return (
    <Switch
      checked={row.isVerified}
      onChange={async (value) => {
        await mutate({ isVerified: value });
      }}
      loading={isLoading}
    />
  );
};

const AddData = ({
  update,
  refech,
  states,
}: {
  update?: News;
  refech: any;
  states: any;
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data2: any) =>
      update ? UpdateUserNews(update?._id, data2) : addNews(data2),
    onSuccess: (data) => {
      messageApi.success(`Updated News ${data?.title}`);
      refech();
      setVisible(false);
      form.resetFields();
    },
  });

  console.log(update);

  return (
    <div>
      {contextHolder}
      <Button
        style={{
          width: "100%",
        }}
        type="primary"
        onClick={() => setVisible(true)}
      >
        {update ? "Update News" : "Add News"}
      </Button>
      <Modal
        title={update ? "Update News" : "Add News"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          layout="horizontal"
          disabled={isLoading}
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              title: update?.title,
              description: update?.description,
              state: update?.state?._id,
              city: update?.city?._id,
              area: update?.area?._id,
              view: update?.view,
            }
          }
          onFinish={(values) => {
            if (update) {
              mutate(values);
              return;
            }
            console.log(values);
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("description", values.description);
            if (values?.video?.file?.originFileObj) {
              formData.append("video", values.video.file.originFileObj);
            }
            if (values?.banner?.file?.originFileObj) {
              formData.append("banner", values.banner.file.originFileObj);
            }
            formData.append("state", values.state);
            formData.append("city", values.city);
            formData.append("area", values.area);
            formData.append("view", values.view);

            mutate(formData);
          }}
        >
          <Form.Item label="Title" name="title" required>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" required>
            <Input />
          </Form.Item>
          {/* video select and banner select */}
          <Form.Item label="Views" name="view" required>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Video"
            name="video"
            required={update ? false : true}
            hidden={update ? true : false}
          >
            <Upload
              // only video
              accept="video/*"
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Banner"
            name="banner"
            required={update ? false : true}
            hidden={update ? true : false}
          >
            <Upload>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <SelectStateCity states={states} form={form} />

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

const View = ({ row }: { row: News }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["comments", row?._id],
    queryFn: () => getAllComments(row?._id),
  });

  const { mutate, isPending: isLoading2 } = useMutation({
    onSuccess: () => {
      messageApi.success(`Deleted News`);
      refetch();
    },
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        View Comments
      </Button>
      <Modal
        title="Comments"
        open={isModalOpen}
        width={800}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <Table
          columns={[
            {
              title: "Action",
              dataIndex: "action",
              render(value, record) {
                return (
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => mutate(record._id)}
                  >
                    <Button danger loading={isLoading2}>
                      Delete
                    </Button>
                  </Popconfirm>
                );
              },
            },
            {
              title: "Comment",
              dataIndex: "text",
            },

            {
              title: "User",
              dataIndex: ["user", "name"],
            },
            {
              title: "Email",
              dataIndex: ["user", "email"],
            },
          ]}
          dataSource={data}
          loading={isLoading}
          scroll={{ x: 600 }}
        />
      </Modal>
    </>
  );
};

const AddComment = ({ row, refetch }: { row: News; refetch: any }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values: any) => await addComment(values),
    onSuccess: () => {
      message.success(`Added Comment`);
      setIsModalOpen(false);
      refetch();
    },
  });
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Add Comments
      </Button>
      <Modal
        title="Comments"
        open={isModalOpen}
        width={400}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          disabled={isLoading}
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onFinish={(values) => mutate({ ...values, newsId: row?._id })}
        >
          <Form.Item label="Count" name="count" required>
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};
