import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
// adminValues: false,

// dashboard: false,

// addNews: false,

// updateNews: false,

// deleteNews: false,

// viewNews: false,

// viewComments: false,

// deleteComments: false,

// addAds: false,

// viewAds: false,

// updateAds: false,

// deleteAds: false,

// addSpinner: false,

// updateSpinner: false,

// deleteSpinner: false,

// viewSpinner: false,

// viewGifts: false,

// viewUpgradeRequest: false,

// acceptRejectUpgradeRequest: false,

// viewAdmin: false,

// deleteAdmin: false,

// updateAdmin: false,

// addAdmin: false,

// addUser: false,

// updateUser: false,

// viewUser: false,

// deleteUser: false,

// giftComplete: false,
interface User {
  _id: string;

  email: string;
  password: string;
  adminValues: boolean;

  dashboard: boolean;

  addNews: boolean;

  updateNews: boolean;

  deleteNews: boolean;

  viewNews: boolean;

  viewComments: boolean;

  deleteComments: boolean;

  addAds: boolean;

  viewAds: boolean;

  updateAds: boolean;

  deleteAds: boolean;

  addSpinner: boolean;

  updateSpinner: boolean;

  deleteSpinner: boolean;

  viewSpinner: boolean;

  viewGifts: boolean;

  viewUpgradeRequest: boolean;

  acceptRejectUpgradeRequest: boolean;

  viewAdmin: boolean;

  deleteAdmin: boolean;

  updateAdmin: boolean;

  addAdmin: boolean;

  addUser: boolean;

  updateUser: boolean;

  viewUser: boolean;

  deleteUser: boolean;

  giftComplete: boolean;
}

export interface IStore {
  user: User | null;
  setUser: (user: User | null) => void;
  logout: () => void;
}

export const useAuth = create<IStore>()(
  devtools(
    persist(
      (set) => ({
        user: null,

        setUser: (user: User | null) => set({ user }),
        logout: () => set({ user: null }),
      }),
      {
        name: "tangent-news-auth",
        getStorage: () => localStorage,
      }
    )
  )
);
