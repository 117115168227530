import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addAds,
  deleteads,
  getAreabyid,
  getCitybyid,
  getNews,
  getStates,
  getads,
  updateAdd,
} from "../utils/api";
import {
  Table,
  Image,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../utils/store";

interface Data {
  news: {
    title: string;
  };
  url: string;

  _id: string;
  type: string;
  area: {
    name: string;
  };
  city: {
    name: string;
  };
  state: {
    name: string;
  };
}

const ADS = () => {
  const user = useAuth((state) => state.user);

  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["ads"],
    queryFn: () => (user?.viewAds ? getads() : []),
  });
  const { data: states } = useQuery({
    queryKey: ["states"],
    queryFn: () => getStates(),
  });
  const { data: news } = useQuery({
    queryKey: ["news"],
    queryFn: () => getNews(),
  });

  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) => await deleteads(values),
    onSuccess: () => {
      messageApi.success(`Deleted`);
      refetch();
    },
  });

  const columns: ColumnsType<Data> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            {user?.updateAds && (
              <AddData
                update={record}
                refech={refetch}
                states={states}
                news={news}
              />
            )}

            {user?.deleteAds && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "URl",
      dataIndex: "url",

      render(value, record) {
        return record?.type === "Video" ? (
          <Link to={value} target="_blank">
            Link
          </Link>
        ) : (
          <Image src={value} height={100} width={100} />
        );
      },
    },
    {
      title: "News",
      dataIndex: "news",
      render(value, record) {
        return <span>{record?.news?.title}</span>;
      },
    },

    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Area",
      dataIndex: "area.name",
      render(value, record) {
        return <span>{record?.area?.name}</span>;
      },
    },
    {
      title: "City",
      dataIndex: "area.name",
      render(value, record) {
        return <span>{record?.city?.name}</span>;
      },
    },
    {
      title: "State",
      dataIndex: "area.name",
      render(value, record) {
        return <span>{record?.state?.name}</span>;
      },
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Advertisement
        </Typography>
        {user?.addAds && (
          <AddData refech={refetch} states={states} news={news} />
        )}
      </div>
      <Table
        footer={
          user?.viewAds
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data ?? []}
        loading={isLoading}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};

export default ADS;

const AddData = ({
  refech,
  states,
  news,
  update,
}: {
  refech: any;
  states: any;
  news: any;
  update?: any;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("type", form);
  const state = Form.useWatch("state", form);
  const city = Form.useWatch("city", form);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values: any) =>
      update ? await updateAdd(update._id, values) : await addAds(values),
    onSuccess: () => {
      messageApi.success(`Added`);
      //reset form
      !update && form.resetFields();
      refech();
      setVisible(false);
    },
    onError: (err: any) => {
      messageApi.error(err?.response?.data?.message);
    },
  });

  const { data: citys, isLoading: isLoading2 } = useQuery({
    queryKey: ["ctiys", state],
    queryFn: () => getCitybyid(state),
  });
  const { data: areas, isLoading: isLoading3 } = useQuery({
    queryKey: ["areas", city],
    queryFn: () => getAreabyid(city),
  });

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Edit Advertisement" : " Add Advertisement"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              news: update?.news?._id,
              type: update?.type,
              url: update?.url,
              externalUrl: update?.externalUrl,

              state: update?.state?._id,
              city: update?.city?._id,
              area: update?.area?._id,
            }
          }
          onFinish={(values) => {
            const data = new FormData();
            data.append("type", values.type);
            if (values.type === "Video") data.append("url", values.url);
            else {
              data.append("externalUrl", values.externalUrl);
              if (values.banner) {
                data.append("image", values.banner.file.originFileObj);
              }
            }
            data.append("state", values.state);
            data.append("city", values.city);
            data.append("area", values.area);
            data.append("news", values.news);
            mutate(data);
          }}
        >
          {" "}
          <Form.Item label="News" name="news" required>
            <Select
              options={news?.map((state: any) => ({
                label: state.title,
                value: state._id,
              }))}
              showSearch
              filterOption={(input, option: any) => {
                return (
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            required
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select
              options={[
                { label: "Video", value: "Video" },
                { label: "Banner", value: "Banner" },
              ]}
            />
          </Form.Item>
          {type === "Video" ? (
            <>
              <Form.Item
                label="URL"
                name="url"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your URl",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="External Url"
                name="externalUrl"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your external url!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Banner"
                name="banner"
                required
                rules={
                  !update
                    ? [
                        {
                          required: true,
                          message: "Please input your banner!",
                        },
                      ]
                    : []
                }
              >
                <Upload name="logo">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </>
          )}
          <Form.Item label="State" name="state" required>
            <Select
              options={states?.map((state: any) => ({
                label: state.name,
                value: state._id,
              }))}
              showSearch
              filterOption={(input, option: any) => {
                return (
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            />
          </Form.Item>
          <Form.Item label="City" name="city" required>
            <Select
              options={citys?.map((state: any) => ({
                label: state.name,
                value: state._id,
              }))}
              loading={isLoading2}
              showSearch
              filterOption={(input, option: any) => {
                return (
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            />
          </Form.Item>
          <Form.Item label="Area" name="area" required>
            <Select
              options={areas?.map((state: any) => ({
                label: state.name,
                value: state._id,
              }))}
              loading={isLoading3}
              showSearch
              filterOption={(input, option: any) => {
                return (
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
