import axios from "axios";

const URL = "https://api.tangentnews.live";

export const login = async (data: any) => {
  const { data: res } = await axios.post(`${URL}/admin/login`, data);
  return res;
};
export const getAllAdmins = async () => {
  const { data: res } = await axios.get(`${URL}/admin/all-admin`);
  return res;
};
export const addComment = async (data: any) => {
  const { data: res } = await axios.post(`${URL}/admin/comment-add`, data);
  return res;
};
export const addUserNotification = async (data: any) => {
  const response = await axios.post(`${URL}/admin/notification`, data);
  return response.data;
};

export const getUsers = async (page: any) => {
  const { data } = await axios.get(`${URL}/admin/users?page=${page}&limit=20`);
  return data;
};

export const addNews = async (data2: any) => {
  const { data } = await axios.post(`${URL}/admin/news`, data2);
  return data;
};

export const getDash = async () => {
  const { data } = await axios.get(`${URL}/admin/dashboard`);
  return data;
};
export const getNews = async () => {
  const { data } = await axios.get(`${URL}/admin/news`);
  return data;
};
export const getUpgradeRequest = async () => {
  const { data } = await axios.get(`${URL}/admin/upgrade-requests`);
  return data;
};
export const getads = async () => {
  const { data } = await axios.get(`${URL}/admin/ads`);
  return data;
};

export const updateAdmin = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/admin-update/${id}`, data2);
  return data;
};

export const getSpinner = async () => {
  const { data } = await axios.get(`${URL}/spinner-rewards`);
  return data;
};
export const getSpinnergifts = async () => {
  const { data } = await axios.get(`${URL}/admin/spinner-record`);
  return data;
};
export const getSystemValues = async () => {
  const { data } = await axios.get(`${URL}/systemvalue`);
  return data;
};

export const addAds = async (data2: any) => {
  const { data } = await axios.post(`${URL}/admin/ads`, data2);
  return data;
};
export const addAdmin = async (data2: any) => {
  const { data } = await axios.post(`${URL}/admin/signup`, data2);
  return data;
};
export const addUser = async (data2: any) => {
  const { data } = await axios.post(`${URL}/admin/user`, data2);
  return data;
};
export const UserNews = async (id: string) => {
  const { data } = await axios.get(`${URL}/admin/user-news/${id}`);
  return data;
};
export const getwallet = async (id: string) => {
  const { data } = await axios.get(`${URL}/admin/user-wallet/${id}`);
  return data;
};
export const UpdateUserNews = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/news/${id}`, data2);
  return data;
};
export const updateRequest = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/upgrade-request/${id}`, data2);
  return data;
};
export const updateAdd = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/ads/${id}`, data2);
  return data;
};
export const deleteUserNews = async (id: string) => {
  const { data } = await axios.delete(`${URL}/admin/news/${id}`);
  return data;
};
export const deleteads = async (id: string) => {
  const { data } = await axios.delete(`${URL}/admin/ads/${id}`);
  return data;
};

export const deleteSpinner = async (id: string) => {
  const { data } = await axios.delete(`${URL}/admin/spinner/${id}`);
  return data;
};
export const completeSpinnerGift = async (id: string) => {
  const { data } = await axios.put(`${URL}/admin/spinner-record/${id}`);
  return data;
};
export const putsystemval = async (data2: any) => {
  const { data } = await axios.put(`${URL}/admin/systemvalue`, data2);
  return data;
};

export const getStates = async () => {
  const { data } = await axios.get(`${URL}/states`);
  return data;
};

export const getCitybyid = async (id: string) => {
  const { data } = await axios.get(`${URL}/city/${id}`);
  return data;
};
export const getAreabyid = async (id: string) => {
  const { data } = await axios.get(`${URL}/area/${id}`);
  return data;
};

export const addSpinner = async (data2: any) => {
  const { data } = await axios.post(`${URL}/admin/spinner`, data2);
  return data;
};

export const getAllComments = async (id: string) => {
  const { data } = await axios.post(`${URL}/comments`, {
    newsId: id,
  });
  return data;
};

export const deleteComment = async (id: string) => {
  const { data } = await axios.delete(`${URL}/admin/comment/${id}`);
  return data;
};

export const updateWallet = async (data2: any) => {
  const { data } = await axios.put(`${URL}/admin/user-wallet`, data2);
  return data;
};

export const userNotifcation = async (id: string) => {
  const { data } = await axios.get(`${URL}/admin/user-notifications/${id}`);
  return data;
};

export const deleteAdmin = async (id: string) => {
  const { data } = await axios.delete(`${URL}/admin/delete-admin/${id}`);
  return data;
};

export const completeSalary = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/user-salary/${id}`, data2);
  return data;
};

export const getBanners = async () => {
  const { data } = await axios.get(`${URL}/admin/banners`);
  return data;
};

export const addBanner = async (data2: any) => {
  const { data } = await axios.post(`${URL}/admin/banner`, data2);
  return data;
};

export const deleteBanner = async (id: string) => {
  const { data } = await axios.delete(`${URL}/admin/banner/${id}`);
  return data;
};

export const updateBanner = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/banner/${id}`, data2);
  return data;
};
export const getLive = async () => {
  const { data } = await axios.get(`${URL}/admin/live`);
  return data;
};

export const updateSpinner = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/spinner/${id}`, data2);
  return data;
};

export const updateLive = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/live/${id}`, data2);
  return data;
};
export const userSerach = async (data: any) => {
  const response = await axios.post(`${URL}/admin/user-search`, data);
  return response.data;
};

export const updateUser = async (id: string, data2: any) => {
  const { data } = await axios.put(`${URL}/admin/user/${id}`, data2);
  return data;
};
