import { useMutation, useQuery } from "@tanstack/react-query";
import { completeSpinnerGift, getSpinnergifts } from "../utils/api";
import { Table, Image, Typography, Switch, message } from "antd";
import type { ColumnsType } from "antd/es/table";

import { useAuth } from "../utils/store";

interface Data {
  _id: string;
  rewardType: string;
  gift: string;
  coin: number;
  probability: number;
}

const SpinnerGift = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["spinner-gifts"],
    queryFn: () => (user?.viewGifts ? getSpinnergifts() : []),
  });
  console.log(data);

  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (id: any) => await completeSpinnerGift(id),
    onSuccess: () => {
      messageApi.success(`Completed`);
      refetch();
    },
  });

  const columns: ColumnsType<Data> = [
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   render(value, record) {
    //     return (
    //       <div>
    //         <Popconfirm
    //           title="Delete the task"
    //           description="Are you sure to delete this task?"
    //           okText="Yes"
    //           cancelText="No"
    //           onConfirm={() => mutate(record._id)}
    //         >
    //           <Button style={{ marginTop: 10 }} loading={isLoading2}>
    //             Complete
    //           </Button>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Gift",
      dataIndex: "gift",

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Completed",
      dataIndex: "isCompleted",
      render(value, record, index) {
        return (
          user?.giftComplete && (
            <Switch
              loading={isLoading2}
              checked={value}
              onChange={(value) => {
                mutate(record._id);
              }}
            />
          )
        );
      },
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Gifts
        </Typography>
        {/* <AddData refech={refetch} /> */}
      </div>
      <Table
        footer={
          user?.viewGifts
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default SpinnerGift;
