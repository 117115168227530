import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addBanner,
  deleteBanner,
  getBanners,
  getStates,
  updateBanner,
} from "../utils/api";
import {
  Table,
  Image,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  InputNumber,
} from "antd";
import type { ColumnsType } from "antd/es/table";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../utils/store";
import SelectStateCity from "../components/SelectStateCity";

interface Data {
  news: {
    title: string;
  };
  url: string;

  _id: string;
  type: string;
  area: {
    name: string;
  };
  city: {
    name: string;
  };
  state: {
    name: string;
  };
}

const Banner = () => {
  const user = useAuth((state) => state.user);

  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["banners"],
    queryFn: () => (user?.viewAds ? getBanners() : []),
  });

  const { mutate, isPending: isLoading2 } = useMutation({
    mutationFn: async (values: any) => await deleteBanner(values),
    onSuccess: () => {
      messageApi.success(`Deleted`);
      refetch();
    },
  });

  const columns: ColumnsType<Data> = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            {user?.updateAds && <AddData update={record} refech={refetch} />}

            {user?.deleteAds && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "URl",
      dataIndex: "url",

      render(value, record) {
        return record?.type === "Image" ? (
          <Image src={value} height={100} width={100} />
        ) : (
          <Link to={value} target="_blank">
            Link
          </Link>
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "State",
      dataIndex: ["state", "name"],
    },
    {
      title: "City",
      dataIndex: ["city", "name"],
    },
    {
      title: "Area",
      dataIndex: ["area", "name"],
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Banner
        </Typography>
        {user?.addAds && <AddData refech={refetch} />}
      </div>
      <Table
        footer={
          user?.viewAdmin
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data ?? []}
        loading={isLoading}
        scroll={{ x: 500 }}
      />
    </div>
  );
};

export default Banner;

const AddData = ({
  refech,

  update,
}: {
  refech: any;

  update?: any;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("type", form);
  const state = Form.useWatch("state", form);
  const city = Form.useWatch("city", form);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values: any) =>
      update ? await updateBanner(update._id, values) : await addBanner(values),

    onSuccess: () => {
      messageApi.success(`Added`);
      //reset form
      !update && form.resetFields();
      refech();
      setVisible(false);
    },
    onError: (err: any) => {
      messageApi.error(err?.response?.data?.message);
    },
  });
  const { data: states, isLoading: isLoading3 } = useQuery({
    queryKey: ["states"],
    queryFn: () => getStates(),
  });

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update Banner" : "Add Banner"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              ...update,
              state: update?.state?._id,
              city: update?.city?._id,
              area: update?.area?._id,
            }
          }
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <Form.Item
            label="Position"
            name="position"
            required
            rules={[
              {
                required: true,
                message: "Please input your position",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            required
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select
              options={[
                { label: "Video", value: "Video" },

                { label: "PreRecorded", value: "PreRecorded" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            required
            rules={[
              {
                required: true,
                message: "Please input your URl",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <SelectStateCity form={form} states={states} />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
