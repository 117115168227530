import {
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  NotificationOutlined,
  TrophyOutlined,
  ToTopOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Image, Layout, Menu, Space, theme } from "antd";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/store";
const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Home", "/", <HomeOutlined />),
  getItem("Users", "/users", <UserOutlined />),
  getItem("News", "/news", <ReadOutlined />),
  getItem("Advertisement", "/ads", <NotificationOutlined />),
  getItem("Spinner", "/spinner", <TrophyOutlined />),
  getItem("Gifts", "/gifts", <TrophyOutlined />),
  getItem("Admins", "/admin", <UserOutlined />),
  getItem("Banner", "/banner", <DesktopOutlined />),
  getItem("Live", "/live", <DesktopOutlined />),
  getItem("Notification", "/add-notification", <DesktopOutlined />),
  //   getItem("User", "sub1", <UserOutlined />, [
  //     getItem("Tom", "3"),
  //     getItem("Bill", "4"),
  //     getItem("Alex", "5"),
  //   ]),
  //   getItem("Team", "sub2", <TeamOutlined />, [
  //     getItem("Team 1", "6"),
  //     getItem("Team 2", "8"),
  //   ]),
  getItem("Reporter Verification", "/upgrade-request", <ToTopOutlined />),
];

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const router = useNavigate();
  const pathname = useLocation().pathname;
  const user = useAuth((state) => state.user);
  const setUser = useAuth((state) => state.setUser);

  React.useEffect(() => {
    if (!user) {
      router("/login");
    }
  }, [user]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Image
          src="/logo.png"
          preview={false}
          height={80}
          style={{
            paddingLeft: 10,
            paddingBlock: 10,
          }}
        />

        <Menu
          theme="dark"
          defaultSelectedKeys={[pathname]}
          mode="inline"
          items={items}
          onClick={({ key }) => {
            router(key as string);
          }}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: "#001529" }}>
          <Space style={{ float: "right", marginRight: 20 }}>
            <Link
              target="_blank"
              to="https://console.firebase.google.com/project/tangent-news-106af/analytics/app/android:com.tangent.news/overview"
            >
              <Button type="primary">Analytics</Button>
            </Link>
            <Button
              type="primary"
              danger
              onClick={() => {
                setUser(null);
              }}
            >
              Logout
            </Button>
          </Space>
        </Header>
        <Content>{children}</Content>
        {/* <Footer style={{ textAlign: "center" }}>Created by</Footer> */}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
