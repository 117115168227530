import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MainLayout from "./components/Layout";
import Users from "./pages/Users";
import News from "./pages/News";
import Request from "./pages/Request";
import ADS from "./pages/Ads";
import Spinner from "./pages/Spinner";
import SpinnerGift from "./pages/SpinnerGift";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Banner from "./pages/Banner";
import LiveVideo from "./pages/LiveVideo";
import Live from "./pages/Live";
import Notification from "./pages/AddNotification";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/users"
        element={
          <MainLayout>
            <Users />
          </MainLayout>
        }
      />
      <Route
        path="/news"
        element={
          <MainLayout>
            <News />
          </MainLayout>
        }
      />
      <Route
        path="/upgrade-request"
        element={
          <MainLayout>
            <Request />
          </MainLayout>
        }
      />
      <Route
        path="/ads"
        element={
          <MainLayout>
            <ADS />
          </MainLayout>
        }
      />
      <Route
        path="/banner"
        element={
          <MainLayout>
            <Banner />
          </MainLayout>
        }
      />
      <Route
        path="/live"
        element={
          <MainLayout>
            <Live />
          </MainLayout>
        }
      />
      <Route
        path="/live/:id"
        element={
          <MainLayout>
            <LiveVideo />
          </MainLayout>
        }
      />
      <Route
        path="/gifts"
        element={
          <MainLayout>
            <SpinnerGift />
          </MainLayout>
        }
      />
      <Route
        path="/spinner"
        element={
          <MainLayout>
            <Spinner />
          </MainLayout>
        }
      />
      <Route
        path="/admin"
        element={
          <MainLayout>
            <Admin />
          </MainLayout>
        }
      />
      <Route
        path="/add-notification"
        element={
          <MainLayout>
            <Notification />
          </MainLayout>
        }
      />
    </Routes>
  );
}

export default App;
